@import "./var.scss";

.wrapper {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
}

.global-statistics {
  height: 30px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 0px 0px 10px 10px;
  padding: 0 10px;
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.75em;
  font-weight: 500;
}

.global-stat-marquee {
  display: none;
}

.crypto-statistics {
  margin-top: 30px;
}

.crypto-categories-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.crypto-categories {
  width: 1325px;
  margin-top: -10px;
  padding-left: 1px;
  height: 50px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  overflow-y: scroll;
  mask-image: -webkit-gradient(linear, 90% top, 100% bottom, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)));
}

.crypto-categories::-webkit-scrollbar {
  display: none;
}

.crypto-categories-buttons {
  width: 75px;
  height: 50px;
  margin-top: -10px;
  margin-bottom: 6.5px;

  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: flex-end;
}

.scroll-button {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.leftscroll-button {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: red;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  display: flex;
  align-items: center;
  justify-content: center;

  left: 1350px;
  top: 15px;
  z-index: 999;
}

.crypto-categ {
  display: flex;
  align-items: center;
  height: 30px;
  border-radius: 15px;
  padding: 0 12px;
  font-size: 0.875em;
  font-weight: 600;
  white-space: nowrap;
  margin-right: 10px;
  box-shadow:
    rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  background-color: white;

  &:last-child {
    margin-right: 0px;
  }
}

.crypto-categ:hover {
  background-color: #f5f5f5;
}

.crypto-categ-active:hover {
  background-color: #e2e5e7;
}

.crypto-categ:nth-child(3),
.crypto-categ:nth-child(4),
.crypto-categ:nth-child(5),
.crypto-categ:nth-child(6),
.crypto-categ:nth-child(7),
.crypto-categ:nth-child(8) {
  padding: 0 15px;
}

.crypto-categ-active {
  background-color: #e2e5e7;
  border: 1px solid #e2e5e7;
}

.category-image {
  height: 60%;
  margin-right: 6px;
  opacity: 0.9;
}

.categ-palka {
  margin-right: 10px;

  height: 18px;
  width: 1px;
  background: $color-border;
  border-left: 1px solid $color-border;
}

.currency-image {
  width: 25px;
}

table {
  width: 100%;
  font-size: 0.875em;
  font-weight: 600;
  border-collapse: collapse;
  padding-bottom: 25px;
}

tr {
  border-top: 1px solid $color-border;
  border-bottom: 1px solid $color-border;
}

th {
  text-align: left;
  font-size: 14px;
  padding: 10px;
  height: 43px;
  cursor: pointer;
}

th:hover .hover-available {
  display: block;
}

td {
  padding: 10px;
  height: 65px;
}

td:nth-child(4),
td:nth-child(5),
td:nth-child(6),
td:nth-child(7) {
  white-space: nowrap;
}

.currency-rank {
  font-size: 0.875em;
  font-weight: 600;
  color: $color-darkgray;
}

.currency-name {
  display: flex;
  align-items: center;

  & img {
    margin-right: 10px;
  }

  & span {
    margin-left: 10px;
    color: $color-darkgray;
  }
}

.currency-chart {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  & svg {
    height: 100%;
  }
}

.al-left {
  text-align: left;
}

.al-center {
  text-align: center;
}

.al-right {
  text-align: right;
}

.fav-star {
  color: $color-darkgray;
  font-size: 1.25em;
  cursor: pointer;
  margin-bottom: -3px;

  &:hover {
    color: $color-fav;
  }
}

.icon-sortup {
  margin-bottom: -5px;
}

.icon-sortdown {
  margin-bottom: 1px;
}

.icon-eth-gas {
  margin-bottom: -2px;
  font-size: 14px;
}

.icon-grew {
  color: $color-up;
  margin-bottom: -7px;
}

.icon-fell {
  color: $color-down;
  margin-bottom: 1px;
}

.crypto-addit-info {
  margin-top: 5px;
  font-size: 12px;
  color: $color-darkgray;
}

.global-value {
  font-weight: 600;
  color: $color-gl-value;
}

.data-statistics {
  height: 250px;
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
}

.data-text {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  & p {
    font-size: 28px;
    font-weight: 600;
  }
}

.data-text-desc {
  & p {
    font-weight: 400;
    font-size: 16px;
  }
}

.data-numbers {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.numbers-top-coins {
  height: 60%;
  text-align: center;

  & p {
    font-size: 24px;
    font-weight: 600;
  }
}

.numbers-statistics {
  height: 35%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.short-stat-block {
  width: 200px;
  height: 100%;
  display: flex;
  justify-content: end;
  border-radius: 5px;
}

.short-stat-numbers {
  width: 95%;
  background-color: white;
  display: flex;
  gap: 5px;
  flex-direction: column;
  justify-content: center;
  border-radius: 0 5px 5px 0;
  padding: 0 10px;
  font-size: 18px;
  box-shadow:
    0 0 transparent,
    0 0 transparent,
    0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);

  & p {
    font-size: 14px;
  }
}

.stat-numbers {
  display: inline;
}

.top-coins-block {
  display: flex;
  justify-content: center;
  gap: 33px;
  align-items: center;
  margin-top: 30px;
}

.top-coin {
  height: 75px;
  width: 75px;
  background-color: white;
  display: flex;
  gap: 2px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  & p {
    font-size: 14px;
    font-weight: 600;
  }

  & img {
    margin-top: 3px;
    height: 40px;
  }

  &:nth-child(1) {
    background-color: #d7d7d7;
  }

  &:nth-child(2) {
    margin-top: -30px;
    background-color: #d4af37;
  }

  &:nth-child(3) {
    background-color: #ad8a56;
  }
}

.data-statistics-main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 25px;

  & .react-multi-carousel-list {
    display: none;
  }
}

.data-title {
  width: 100%;
  // height: 58px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  & h1:nth-child(1) {
    font-size: 30px;
    font-weight: 600;
  }
}

.font-14 {
  font-size: 14px;
}

.data-title-info {
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.currency-rank-center {
  text-align: center;
}

.data-blocks {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.data-block {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width: 30%;
  border-radius: 10px;
  padding: 12px 16px;
  background-color: white;
}

.data-block-title {
  width: 100%;
  height: 25px;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  & p {
    margin-left: 10px;
  }
}

.data-block-table {
  width: 100%;
  height: 135px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.block-table-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 24px;
  font-size: 12px;
}

// .carousel-container {
//   display: none !important;
// }

.row-title {
  display: flex;
  align-items: center;

  & img {
    height: 16px;
    margin-right: 8px;
  }
}

.row-number {
  width: 8px;
  margin-left: 5px;
  margin-right: 12px;
  color: #525252;
  font-weight: 600;
}

.row-name {
  font-weight: 600;
  margin-right: 8px;
}

.row-ticker {
  color: $color-darkgray;
  font-weight: 600;
}

.row-perc {
  color: #525252;
  font-weight: 600;
}

.row-country {
  max-height: 15px;
  margin-right: 8px;
  background-color: $color-border;
  padding: 0px;
  font-size: 12px;
  font-weight: 600;
  padding: 0 4px;
  border-radius: 6px;
  display: flex;
  align-items: center;
}

.row-flag {
  width: 22px;
}

.data-title-desc {
  color: #58667e;
}

.data-title-desc-2 {
  line-height: 1.25;
  color: #58667e;
  min-width: 650px;
  width: 50%;
  font-size: 14px;
}

.bold-text {
  font-weight: 700;
}

.data-title-grow {
  font-weight: 700;
  color: $color-up;
}

.data-title-fall {
  font-weight: 700;
  color: $color-down;
}

.loader-anim-row {
  text-align: center;
  border: 0px solid white;
  height: calc(100vh - 650px);
}
.loader-anim {
  margin-left: 100px;
}

.loader-anim span {
  position: absolute;
  width: 6px;
  height: 90px;
  margin-top: -45px;
  border-radius: 10px;
  background-color: $color-darkgray;
  animation: animate 0.8s infinite;
  animation-direction: alternate-reverse;
}
.loader-anim span:nth-child(1) {
  margin-left: 0px;
}
.loader-anim span:nth-child(2) {
  margin-left: -14px;
  animation-delay: 0.1s;
}
.loader-anim span:nth-child(3) {
  margin-left: -28px;
  animation-delay: 0.2s;
}
.loader-anim span:nth-child(4) {
  margin-left: -42px;
  animation-delay: 0.3s;
}
.loader-anim span:nth-child(5) {
  margin-left: -56px;
  animation-delay: 0.4s;
}
.loader-anim span:nth-child(6) {
  margin-left: -70px;
  animation-delay: 0.5s;
}
.loader-anim span:nth-child(7) {
  margin-left: -84px;
  animation-delay: 0.6s;
}
.loader-anim span:nth-child(8) {
  margin-left: -98px;
  animation-delay: 0.7s;
}
@keyframes animate {
  0% {
    height: 5px;
    margin-top: 0;
    transform: rotate(40deg);
    background-color: $color-down;
  }
  100% {
    background-color: $color-up;
    height: 90px;
    transform: rotate(0deg);
  }
}

.th-flex {
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: flex-end;
}

.flex-cap {
  margin-right: 15px;
}

.flex-start {
  justify-content: flex-start !important;
}

.th-flex-icons {
  display: flex;
  flex-direction: column;
}

.th-icon-up {
  margin-top: 9px;
  color: #363636;
}

.th-icon-down {
  margin-top: -3px;
  color: #363636;
}

.th-icon-updown {
  margin-bottom: -2px;
  color: #363636;
}

.hover-available {
  display: none;
}

.display-none {
  display: none;
}

.search-box {
  position: relative;
  z-index: 5;
  line-height: 40px;
  padding: 5px;
  border-radius: 25px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.search-input {
  z-index: 999;
  font-weight: 600;
  outline: none;
  border: none;
  line-height: 36px;
  width: 0px;
  float: left;
  font-size: 14px;
  transition: 0.7s ease;
  font-family: "Inter", sans-serif;
  background-color: transparent;
}
.search-btn {
  background-color: white;
  cursor: pointer;
  border: 1px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 10px;
  border-radius: 50%;
  float: right;
  color: #353535;
  transition: 0.4s ease;
  background-color: transparent;
}
.search-box:hover > .search-input {
  width: 200px;
  margin: 0px 8px;
}
.search-input:focus {
  width: 200px;
  margin: 0px 8px;
}
.search-input:focus + .search-btn {
  background: #efeff0;
}
.search-box:hover > .search-btn {
  background: #efeff0;
}

.dropdown-search {
  display: none;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  position: absolute;
  z-index: -1;
  padding: 20px 10px 0px 10px;
  margin-left: -5px;
  margin-top: 18px;
  width: 100%;
  background-color: white;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;

  & p {
    font-size: 12px;
  }

  & a:last-child .dropdown-found {
    border-bottom: 1px solid $color-darkgray;
  }
}

.search-input:focus ~ .dropdown-search {
  display: block;
}

hr {
  height: 1px;
  background-color: $color-darkgray;
  border: none;
}

.dropdown-found {
  line-height: 14px;
  border-bottom: 1px solid $color-darkgray;
  padding-right: 5px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background-color: #efeff0;
  }

  & > p {
    color: #525252;
    font-weight: 600;
  }

  & div {
    display: flex;
    align-items: center;

    & p:nth-child(2) {
      font-size: 12px;
      font-weight: 600;
      margin-right: 5px;
    }

    & p:nth-child(3) {
      font-size: 12px;
      font-weight: 600;
      color: #525252;
    }
  }

  & img {
    height: 20px;
    width: 20px;
    margin-right: 5px;
  }
}

.display-block {
  display: block;
}

.dropdown-search a:last-child .dropdown-found {
  border: 0px solid white;
}

.dropdown-search:hover {
  display: block;
}

.crypto-statistics {
  margin-bottom: 30px;
}

.button-up-block {
  height: 40px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 75px;
}

.button-up {
  margin-bottom: -50px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 100%;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 50%;
  cursor: pointer;
}

.button-up-icon {
  height: 25px;
  width: 25px;
}

.scroll-button-left {
  margin-right: 2px;
}

.scroll-button-right {
  margin-left: 2px;
}

.cookie-consent-main {
  width: 100%;
  position: fixed;
  bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;

  & .cookie-consent-wrapper {
    width: 50%;
    box-shadow:
      rgba(0, 0, 0, 0.07) 0px 1px 2px,
      rgba(0, 0, 0, 0.07) 0px 2px 4px,
      rgba(0, 0, 0, 0.07) 0px 4px 8px,
      rgba(0, 0, 0, 0.07) 0px 8px 16px,
      rgba(0, 0, 0, 0.07) 0px 16px 32px,
      rgba(0, 0, 0, 0.07) 0px 32px 64px;
    background-color: white;
    padding: 25px;
    border-radius: 20px;

    & .cookie-consent-content-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      & .cookie-consent-main-content {
        width: 80%;
        display: flex;
        flex-direction: column;
        gap: 10px;

        & .cookie-content-scrollblock {
          margin-bottom: 10px;
        }

        & .cookie-consent-footer {
          display: flex;
          align-items: center;
          font-weight: 600;
          color: blue;

          & .cookie-consent-footer-arrow {
            color: black;
            font-size: 20px;
          }
        }
      }

      & .cookie-consent-accept-btn {
        cursor: pointer;
        width: 200px;
        height: 50px;
        background-color: blue;
        color: white;
        border: none;
        outline: none;
        border-radius: 5px;
      }

      & .cookie-consent-accept-btn:hover {
        background-color: lighten(blue, 10%);
      }
    }
  }
}

.crypto-pagination {
  width: 100%;
  display: flex;
  justify-content: center;
}

.MuiPagination-ul {
  .Mui-selected {
    background-color: #1c3eff !important;
  }
}
