header {
  height: 70px;
  width: 100%;
  background-color: white;

  & button {
    width: 225px;
    font-size: 0.95em;
    background-color: #f6851b;
    font-weight: 500;
    font-family: "Inter", "Roboto", sans-serif;
    padding: 10px 15px;
    outline: none;
    border: 0px solid;
    color: white;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    & img {
      height: 20px;
      margin-right: 10px;
    }
  }
}

.wrapper-header {
  height: 100%;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;

  display: flex;
  justify-content: space-between;
  align-items: center;

  & .nav-menu-icon {
    display: none;
  }
}

.header-logo {
  display: flex;
  align-items: center;

  & img {
    width: 40px;
    margin-right: 10px;
  }

  & p {
    font-size: 2em;
    font-family: "GravitypeMinigap", sans-serif;
  }
}

.stroke {
  width: 100%;
  max-width: 850px;
  font-size: 1em;
  font-weight: 600;
}

a {
  color: black;
  text-decoration: none;
}

.ul-header {
  display: flex;
  justify-content: space-between;
  list-style: none;
}

.ul-header li {
  position: relative;
}

.header-button {
  text-decoration: none;
  color: #5c5c5c;
  display: inline-block;
  padding: 5px 5px;
  position: relative;
}

.header-button:hover {
  color: black;
}

.header-button:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: black;
  transition:
    width 0.5s ease 0s,
    left 0.5s ease 0s;
  width: 0;
}

.header-button:hover:after {
  width: 100%;
  left: 0;
}

.meta-connected {
  width: 225px;
  font-size: 0.95em;
  background-color: #f6851b;
  font-weight: 500;
  font-family: "Inter", "Roboto", sans-serif;
  padding: 7px 13px;
  outline: none;
  border: 2px solid #f6851b;
  color: white;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  & img {
    height: 20px;
  }
}

.icon-header {
  font-size: 22px;
}

.tooltip-header-button {
  display: flex;
  align-items: center;

  svg {
    font-size: 16px;
    margin-right: 5px;
    margin-top: -2px;
  }
}

.bread-block {
  width: 100%;
  padding: 15px 0px;
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 400;

  & .breadcrumb-link {
    position: relative;
    text-decoration: none;
    color: black; /* Колір посилання */
    padding: 0 5px;
    font-size: 16px;
  }

  & .breadcrumb-link:hover {
    color: black; /* Зміна кольору при наведенні */
  }

  & .breadcrumb-link::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 100%;
    height: 2px;
    background-color: black; /* Колір лінії */
    transform: scaleX(0); /* Лінія прихована спочатку */
    transition: transform 0.3s ease; /* Анімація появи */
  }

  & .breadcrumb-link:hover::after {
    transform: scaleX(1); /* Лінія з'являється при hover */
  }

  & .breadcrumb-icon {
    font-size: 12px;
  }
}
