@import "./var.scss";

.coin-addit {
  width: 100%;
  display: flex;
  gap: 25px;
  margin-top: 25px;
  margin-bottom: 25px;
}

.coin-addit-graph {
  background-color: white;
  width: 70%;
  height: 630px;
  border-radius: 16px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.coin-info-text {
  background-color: white;
  width: calc(70% - 20px);
  border-radius: 16px;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-bottom: 50px;

  h1 {
    margin-bottom: 10px;
  }
}

.coin-addit-info {
  width: 30%;
  height: 100%;
  background-color: white;
  border-radius: 16px;
  padding: 20px 20px 5px 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  & > p {
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 15px;
  }
}

.info-blocks {
  width: 100%;
}

.info-block {
  border-top: 1px solid $color-border;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 600;
}

.info-left,
.info-right {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.info-left {
  text-align: left;
}

.info-right {
  text-align: right;
}

.coin-main {
  margin-top: 50px;
  width: 100%;
  height: 300px;

  display: grid;
  grid-template-columns: 5fr repeat(4, 2fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.coin-main-mobile {
  display: none;
}

.main-info {
  grid-area: 1 / 1 / 2 / 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.main-price {
  grid-area: 1 / 2 / 2 / 4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  border-bottom: 1px solid #eff2f5;
}
.main-desc {
  position: relative;
  grid-area: 1 / 4 / 2 / 6;
  border-bottom: 1px solid #eff2f5;
}
.main-socials {
  grid-area: 2 / 1 / 3 / 2;

  padding-right: 75px;
}

.main-cap {
  grid-area: 2 / 2 / 3 / 3;
  border-right: 1px solid #eff2f5;
}

.main-vol {
  grid-area: 2 / 3 / 3 / 4;
  border-right: 1px solid #eff2f5;
}
.main-change {
  grid-area: 2 / 4 / 3 / 5;
  border-right: 1px solid #eff2f5;
}
.main-sup {
  grid-area: 2 / 5 / 3 / 6;
}
.info-basic {
  width: 100%;
  padding-right: 75px;

  & div {
    display: flex;
    align-items: center;

    & img {
      height: 32px;
      width: 32px;
      margin-right: 12px;
    }

    & p:nth-child(2) {
      font-size: 32px;
      font-weight: 700;
      margin-right: 10px;
    }

    & p:nth-child(3) {
      padding: 4px 7px;
      border-radius: 4px;
      background-color: #eff2f5;
      font-weight: 600;
      font-size: 12px;
      height: 24px;
      color: #58667e;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.info-tags {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding-right: 75px;
}

.info-tag,
.info-tag-rank {
  text-transform: capitalize;
  color: #58667e;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  border-radius: 4px;
  padding: 2px 5px;
  font-size: 12px;
  font-weight: 600;
  background-color: #eff2f5;
}

.info-tag-rank {
  color: white;
  background-color: #808a9d;
}

.price-price {
  width: 100%;
  display: flex;
  flex-direction: column;
  & > p {
    font-size: 12px;
    font-weight: 500;
    color: #58667e;
  }

  & div {
    display: flex;
    align-items: center;
    gap: 15px;
  }
}

.price-value {
  font-size: 32px;
  font-weight: 700;
}

.price-pers {
  font-size: 14px;
  padding: 5px 8px;
  border-radius: 8px;
  font-weight: 600;
  color: white;
}

.price-pers-small {
  font-size: 14px;
  padding: 3px 5px;
  border-radius: 8px;
  font-weight: 600;
  color: white;
}

.font-12 {
  font-size: 12px;
}

.price-stats {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  & input {
    width: 70%;
  }

  & div {
    text-align: center;
    font-size: 12px;
    font-weight: 400;

    & span {
      font-weight: 600;
    }
  }
}

.block-24h {
  font-size: 11px;
  font-weight: 500;
  border-radius: 4px;
  font-weight: 600;
  padding: 2px 4px;
  background-color: #eff2f5;
  color: #58667e;
}

.desc-updated {
  position: absolute;
  top: 12px;
  right: 0;
  font-size: 12px;
  font-weight: 500;
  color: #58667e;
}

.display-none {
  display: none !important;
}

.desc-description {
  max-height: 145px;
  overflow: hidden;

  h3 {
    margin-bottom: 2px;
  }

  & p {
    overflow: hidden;
    max-height: 150px;
    font-size: 12px;
    font-weight: 500;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;

    & code {
      font-family: "Inter";
    }
  }
}

.button-more {
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & button {
    cursor: pointer;
    background-color: transparent;
    border: 0px solid black;
    font-size: 12px;
    font-weight: 600;
    color: #3861fb;
    text-align: right;
  }
}

.icon-more {
  color: #3861fb;
}

.desc-description-full {
  overflow-y: scroll;

  & p {
    display: block;
    overflow: initial;
  }
}

.button-more-full {
  display: none;
}

.icon-sortup-stat {
  margin-bottom: -8px;
}

.icon-sortdown-stat {
  margin-top: -3px;
}

.main-cap,
.main-vol,
.main-change,
.main-sup {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: 600;
  gap: 8px;

  & p {
    font-weight: 700;
    display: flex;
    align-items: center;
  }
}

.main-vol,
.main-change {
  padding: 0px 20px;
}

.main-sup {
  padding: 0px 0px 0px 20px;
}

.main-stat-name {
  height: 18px;
  display: flex;
  align-items: center;
  gap: 5px;
  color: #58667e;
}

.main-stat-row {
  height: 18px;
}

.change-block {
  display: flex;
  align-items: center;
  gap: 5px;
}

.main-socials {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
}

.socials,
.webs {
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  gap: 8px;
}

.social-block {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;

  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;

  background-color: #eff2f5;

  border-radius: 4px;
  padding: 2px 5px;
  height: 30px;

  & svg {
    width: 16px;
    height: 16px;
  }

  & span {
    font-size: 12px;
    color: #58667e;
  }
}

.facebook-color {
  color: #3b5998;
}

.telegram-color {
  color: #229ed9;
}

.reddit-color {
  color: #ff5700;
}

.twitter-color {
  color: #00acee;
}

.github-color {
  color: #171515;
}

.web-icon {
  color: #222531;
}

.web-link-icon {
  color: #808a9d;
}

#range-coin {
  cursor: default;
  height: 21px;
  -webkit-appearance: none;
  width: 100%;
  background-color: transparent;
}
#range-coin:focus {
  outline: none;
}
#range-coin::-webkit-slider-runnable-track {
  cursor: default;
  width: 100%;
  height: 6px;
  box-shadow: 0px 0px 0px transparent;
  background: #cfd6e4;
  border-radius: 10px;
  border: 0px solid #ffffff;
}
#range-coin::-webkit-slider-thumb {
  cursor: default;
  box-shadow: 0px 0px 0px #000000;
  border: 0px solid #000000;
  height: 15px;
  width: 5px;
  border-radius: 5px;
  background: #8792a5;
  -webkit-appearance: none;
  margin-top: -4.5px;
}
#range-coin:focus::-webkit-slider-runnable-track {
  cursor: default;
  background: #cfd6e4;
}
#range-coin::-moz-range-track {
  cursor: default;
  width: 100%;
  height: 6px;
  box-shadow: 0px 0px 0px transparent;
  background: #cfd6e4;
  border-radius: 10px;
  border: 0px solid #ffffff;
}
#range-coin::-moz-range-thumb {
  cursor: default;
  box-shadow: 0px 0px 0px #000000;
  border: 0px solid #000000;
  height: 15px;
  width: 5px;
  border-radius: 5px;
  background: #8792a5;
}
#range-coin::-ms-track {
  cursor: default;
  width: 100%;
  height: 6px;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
#range-coin::-ms-fill-lower {
  cursor: default;
  background: #cfd6e4;
  border: 0px solid #ffffff;
  border-radius: 20px;
  box-shadow: 0px 0px 0px transparent;
}
#range-coin::-ms-fill-upper {
  cursor: default;
  background: #cfd6e4;
  border: 0px solid #ffffff;
  border-radius: 20px;
  box-shadow: 0px 0px 0px transparent;
}
#range-coin::-ms-thumb {
  cursor: default;
  margin-top: 1px;
  box-shadow: 0px 0px 0px #000000;
  border: 0px solid #000000;
  height: 15px;
  width: 5px;
  border-radius: 5px;
  background: #58667e;
}
#range-coin:focus::-ms-fill-lower {
  cursor: default;
  background: #cfd6e4;
}
#range-coin:focus::-ms-fill-upper {
  cursor: default;
  background: #cfd6e4;
}

.coin-addit-graph {
  position: relative;
}

.chart-price {
  width: 100%;
  height: 75%;
  border-radius: 16px 16px 0 0;
}

.chart-volume {
  width: 100%;
  height: 18%;
  border-radius: 0 0 16px 16px;
}

.chart-buttons {
  height: 7%;
  display: flex;
  align-content: center;
  justify-content: center;
}

.buttons-blocks {
  margin-top: 10px;
  display: flex;
  align-content: center;
  justify-content: center;
  background-color: #eff2f5;
  gap: 2px;
  border-radius: 8px;
  padding: 3px;
}

.button-block {
  display: flex;
  vertical-align: middle;
  align-content: center;
  justify-content: center;
  color: #58667e;
  padding: 7px 9px;
  font-size: 12px;
  font-weight: 700;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: #f8fafd;
  }
}

.button-block-active {
  background-color: white;
}

.animation-chart {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  & div {
    margin-top: -90px;
    margin-left: 100px;
  }
}

svg g rect[fill="red"] {
  cursor: crosshair !important;
}
