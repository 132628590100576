@import "./var.scss";

.whale-blockchain {
  height: 50px;
  width: 100%;
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  gap: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  position: relative;
}

.blockchain-block {
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 14px;
  font-weight: 600;
  padding: 0 5px;

  & img {
    width: 28px;
    height: 28px;
    margin-right: 8px;
  }

  & p::first-letter {
    text-transform: capitalize;
  }
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  top: 30px;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 9999;
}

.blockchain-status {
  margin-top: 2px;
  margin-left: 10px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.whale-map {
  height: 550px;
  width: 100%;
  margin-bottom: 25px;
  background-color: #151515;
}

.iframe_realtime {
  border: 0px none;
  height: 100%;
  width: 100%;
}

.marquee-container {
  height: 100%;
}

.overlay {
  --gradient-width: 50px !important;
}

.overlay::after,
.overlay::before {
  border-radius: 10px;
}

.blockchain-palka {
  margin-left: 20px;
  margin-right: 6px;
  height: 18px;
  width: 1px;
  background: $color-border;
  border-left: 1px solid $color-border;
}

.whale-transactions-stat {
  height: 440px;
  width: 100%;
  display: flex;
  gap: 10px;
  margin-bottom: 25px;
}

.stat-realtime {
  background-color: #2b2b2b;
  width: 50%;
  height: 100%;
  border-radius: 10px 0px 0px 10px;
}

.stat-map {
  position: relative;
  background-color: #151515;
  width: 50%;
  height: 100%;
  border-radius: 0px 10px 10px 0px;
}

.whale-realtime {
  margin: 0 auto;
  width: 100%;
  margin-bottom: 25px;
}

.transaction-block {
  margin: 0 auto;
  overflow: hidden;
  width: 100%;
  opacity: 0;
  border-radius: 10px;

  & iframe {
    border-radius: 10px;
    border: 0px none;
    margin-left: -46px;
    margin-top: -274px;
    width: 1200px;
    height: 710px;
  }
}

.map-block {
  width: 100%;
  height: 100%;
  opacity: 0;
  overflow: hidden;
  border-radius: 0px 10px 10px 0px;
}

.block-visible {
  opacity: 1;
}

.whale-transactions {
  width: 100%;
  margin-bottom: 25px;
}

.transaction-name {
  display: flex;
  align-items: center;

  & img {
    height: 25px;
    width: 25px;
    margin-right: 10px;
  }

  & span {
    margin-left: 10px;
    color: $color-darkgray;
  }
}

.transaction-address {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 350px;

  & p {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.owner-type {
  display: flex;

  & p {
    color: white;
    padding: 2px 10px;
    border-radius: 10px;
    background-color: $color-darkgray;
  }
}

.owner-exchange {
  background-color: #f7931a !important;
}

.owner-other {
  background-color: #1a8cf7 !important;
}

.owner-token {
  background-color: #1fe70d !important;
}

.align-right {
  text-align: right;
}

.th-flex {
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: flex-end;
}

.th-flex-time {
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: flex-start;
}

.trans-cursor {
  cursor: pointer !important;
}

.whale-realtime {
  position: relative;
}

.lds-ellipsis {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.whale-error {
  width: 100%;
  text-align: center;
  margin-top: 20%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;

  & > p {
    font-size: 1.5rem;
    font-weight: 600;
  }
}

.whale-plugin-block {
  border: 1px solid lightgray;
  border-radius: 20px;
  padding: 10px 20px;

  display: flex;
  align-items: center;
  gap: 10px;

  & img {
    width: 50px;
  }

  & p {
    font-size: 1.125rem;
  }

  & a {
    margin-left: 25px;
    background-color: #0957d0;
    color: white;
    padding: 10px 15px;
    border-radius: 15px;
  }
}
